@use "../../../styles/abstracts" as *;

.modules {
  &__auth-form {
    background-color: $formBg;
    width: 35vw;
    height: 30vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    form {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;

      width: 80%;
      height: 65%;

      div {
        width: 100%;
        height: 3vw;
      }

      h1 {
        align-self: flex-start;
      }

      button {
        width: 100%;
        height: 3vw;
      }
    }

    > p {
      font-size: 1vw;
      position: relative;
      top: -5px;
    }
  }

  @media only screen and (min-width: 1500px) {
    &__auth-form {
      width: 31vw;
      height: 27vw;
    }
  }

  @media only screen and (max-width: 1200px) {
    &__auth-form {
      width: 38vw;
      height: 32vw;

      > p {
        font-size: 1.2vw;
      }

      form {
        button {
          height: 3.5vw;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    &__auth-form {
      width: 45vw;
      height: 39vw;
      > p {
        font-size: 1.5vw;
      }

      form {
        button {
          height: 4vw;
        }
      }
    }
  }

  @media only screen and (max-width: 750px) {
    &__auth-form {
      width: 57vw;
      height: 49vw;
      > p {
        font-size: 1.7vw;
      }
      form {
        height: 70%;
        button {
          height: 4.5vw;
        }
      }
    }
  }

  @media only screen and (max-width: 630px) {
    &__auth-form {
      width: 70vw;
      height: 57vw;
      > p {
        font-size: 2vw;
      }
      form {
        height: 79%;
        button {
          height: 6vw;
        }
      }
    }
  }

  @media only screen and (max-width: 485px) {
    &__auth-form {
      width: 80vw;
      height: 65vw;
      > p {
        font-size: 2.3vw;
      }
      form {
        height: 85%;
        button {
          height: 8vw;
        }
      }
    }
  }
}
