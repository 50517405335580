@use "./abstracts" as *;

body {
  margin: 0px;
  padding: 0px;
  box-sizing: content-box;
  background-color: $bg;
  font-family: "Varela", sans-serif;
  overflow: hidden;
  --webkit-tap-highlight-color: transparent;
}
