@use "../../../styles/abstracts" as *;

.modules {
  &__options-form {
    background-color: $formBg;
    width: 35vw;
    height: 30vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    form {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;

      width: 80%;
      height: 80%;

      div {
        width: 100%;
        height: 3vw;
      }

      h1 {
        align-self: flex-start;
      }

      button {
        width: 100%;
        height: 3vw;
      }
    }
  }

  @media only screen and (min-width: 1500px) {
    &__options-form {
      width: 31vw;
      height: 27vw;
    }
  }

  @media only screen and (max-width: 1200px) {
    &__options-form {
      width: 38vw;
      height: 32vw;
      form {
        height: 85%;
        button {
          height: 3.5vw;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    &__options-form {
      width: 45vw;
      height: 39vw;
      form {
        height: 90%;
        button {
          height: 4vw;
        }
      }
    }
  }

  @media only screen and (max-width: 750px) {
    &__options-form {
      width: 57vw;
      height: 60vw;
      form {
        height: 95%;
        h1 {
          height: 3vw;
        }
        button {
          height: 4.5vw;
        }
      }
    }
  }

  @media only screen and (max-width: 630px) {
    &__options-form {
      width: 70vw;
      height: 65vw;
      form {
        height: 95%;
        h1 {
          height: 3vw;
        }
        button {
          height: 7vw;
        }
      }
    }
  }

  @media only screen and (max-width: 485px) {
    &__options-form {
      width: 80vw;
      height: 110vw;
      form {
        height: 95%;
        button {
          height: 10vw;
        }
      }
    }
  }
}
