@use "../../../styles/abstracts" as *;

.elements {
  &__timerImage {
    div {
      width: 100%;
      height: 100%;
      position: absolute;

      &:nth-child(1) {
        background-color: $timerBg;
        z-index: 1;
      }

      &:nth-child(2) {
        background: radial-gradient(
          50% 50% at 50% 50%,
          $timerFront 3.65%,
          rgba(38, 103, 255, 0) 100%
        );
        z-index: 2;
      }

      &:nth-child(3) {
        width: 30%;
        height: 30%;
        background-color: $timerFront;
        opacity: 0.1;
        z-index: 3;
        filter: blur(15px);

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    }
  }
}
