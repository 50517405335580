@use "../../../styles/abstracts" as *;

.elements {
  &__button {
    background-color: $formMain;
    color: $formSecondary;
    border: 1px solid rgba($formSecondary, 0.5);
    border-radius: 49px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
    cursor: pointer;

    &:disabled {
      background-color: rgba($formMain, 0.5);
      color: rgba($formSecondary, 0.5);
      cursor: default;
    }
  }
}
