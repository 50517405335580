.modules {
  &__timer {
    position: relative;
    display: flex;
    width: 40vw;
    height: 40vw;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    div {
      &:nth-child(3) {
        transition: var(--transition);
      }
    }

    &_inhale {
      @extend .modules__timer;
      div {
        &:nth-child(3) {
          filter: blur(4px);
          opacity: 0.5;
          transform: scale(3.2) translate(calc(-50% / 3.2), calc(-50% / 3.2));
          transition: var(--transition);
        }
      }
    }

    &_hold {
      @extend .modules__timer;
      div {
        &:nth-child(3) {
          filter: blur(10px);
          opacity: 0.8;
          transform: scale(3.2) translate(calc(-50% / 3.2), calc(-50% / 3.2));
          transition: var(--transition);
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    &__timer {
      width: 55vw;
      height: 55vw;
    }
  }

  @media only screen and (max-width: 630px) {
    &__timer {
      width: 65vw;
      height: 65vw;
    }
  }
}
